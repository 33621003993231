
.hero-cat {
  
  position: relative;
  z-index: 1;
  padding-top: 5rem;
  padding-bottom: 2.6rem;
  background-size: cover;
  background-position: 50% 50%;
  justify-content: center;
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
  background: none !important;

  .h1 {
    font-size: 3.8rem;
    font-weight: 300;
    line-height: 1.1;
    margin-bottom: 0.9rem;
    display: inline-flex;
  }
  
}
.hero-cat-content {
  //overflow: hidden;
}

.hero-cat-tags {
  .tags {
    padding: 1rem;
  }
}

.hero-cat-back-link {
  display: inline-flex;
  align-items: center;
  font-size: 4rem;
  margin-left: -1em;
  padding-right: 1rem;
  
  i {
    &:before {
      padding: 0;
    }
  }
}

.hero-cat-icon {
  max-height: 14rem;
  margin-right: 3rem;
  //filter: saturate(0) brightness(0.64) contrast(5.4);
}

.hero-cat-icon-font {
  display: none;
  font-size: 12rem;
  //filter: saturate(0) brightness(0.64) contrast(5.4);
}

.hero-cat-description {
  color: inherit;
  font-weight: 300;
  font-size: 2rem;
  //max-width: 50%;
}
