
%lightBoxBtnStyle {
  display: block;
  cursor: pointer;
  @include user-select(none);
  padding: 0 0.6em;
  text-align: center;
  line-height: 2.1em;
  margin-bottom: 0.3rem;
  color: #fff !important;
  font-size: 4rem;
  
  &:before {
    margin: 0;
    padding: 0;
    vertical-align: bottom;
  }
}

.lightBox {
  position: fixed;
  z-index: 11000;
  top: 0; bottom: 0; left: 0; right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.lightBoxHeader {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11000;
  color: #fff;
  overflow: hidden;
  font-family: $secondaryFont;
  transition: padding .3s, height .3s, min-height .3s;
  font-size: 1.8rem;
  > a {
    @extend %lightBoxBtnStyle;
    float: right;
    margin: 0.3rem;
  }
  @media #{$mediaSmall} {
    top: 0.3rem;
    right: 0.3rem;
    transform: none;
  }
}
#lightBoxCloseBtn {
  &:before {
    //padding-right: 0.5em;
  }
}
.lightBoxFooter {
  height: auto;
  width: 100%;
  padding: 1rem 20%;
  text-align: center;
  background: transparent;
  color: #fff;
  font-family: $secondaryFont;
  font-size: 1.8rem;
  bottom: 0;
  &:empty {
    padding: 0;
    opacity: 0;
  }
}
#lightBoxCaption {
  overflow: hidden;
}
.lightBoxControls {
  position: absolute;
  z-index: 1000;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  top: -0.3rem;
  left: 0;
  transform: translateY(-100%);
  a {
    @extend %lightBoxBtnStyle;
    margin: 0 0.15rem;
    &.icon-arrow-left,
    &.icon-arrow-right {
      padding-left: 1em;
      padding-right: 1em;
      &:before {
        font-size: 2.3em;
      }
    }

  }
}
#lightBoxZoomBtn {
  &:before {
    font-size: 1.1em;
  }
}
.lightBoxContent {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}
.lightBoxImageWrapper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  > img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    //transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    @include user-select(none);
  }
}
