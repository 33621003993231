
$siMaxHeight: 40vh;

.si-wrapper-top {
  max-height: calc(#{$siMaxHeight} + 15px);
}
.si-has-border .si-pointer-bg-top {
  transform: translateY(-1px);
}
.si-content-wrapper {
  overflow: visible;
}
.si-content {
  overflow: visible;
  max-width: 30rem !important;
  max-height: 100% !important;
  
  .scroll-track {
    //height: calc(100% - 4.2rem);
    height: 100%;
    top: 0;
    right: 0;
  }
  
  .scroll-inner {
    max-height: $siMaxHeight;
  }
  
}

.si-close-button {
  
  z-index: 10;
  background: none;
  color: #fff;
  text-shadow: 1px 1px #000;
  
  &:hover {
    background: none;
    color: #fff;
  }
  font-size: 3rem;
  left: 100%;
  width: 3rem !important;
  height: 3rem !important;
  display: flex !important;
  padding: 0 !important;
  opacity: 1!important;
  align-items: center;
  justify-content: center;
}

.iw {
  
  min-width: 30rem;
  padding: 0 0 2rem;
  
  h1 {
    //@include textCenterLines(1rem) {
    //  background: none;
    //  height: 2px;
    //}
    //font-size: 1.5rem;
    //font-weight: 700;
    //font-family: $secondaryFont;
    //margin-bottom: 1.6rem;
    //padding-bottom: 0.5rem;
  }
  h2 {
    //font-size: 1.2rem;
    //font-weight: 600;
    //font-family: $secondaryFont;
  }
  
  .img-wrap {
    width: 100%;
    position: relative;
    display: flex;
    padding-bottom: 80%;
    img {
      transition: transform .25s;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}


.iw-item {
  a {
    color: inherit !important;
  }
}

.iw-item-main {
  display: block;
  overflow: hidden;
}

.iw-item-main-img {
  width: 8rem;
  height: 8rem;
  float: left;
  margin-bottom: .6rem;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.iw-item-main-content {
  padding: 0 1.2rem 0;
  overflow: hidden;
  font-size: 1.3rem;
}

.iw-item-main-h {
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 0.6rem;
  line-height: 1.1;
  margin-top: .2rem;
}

.iw-item-footer {
  font-size: 1.3rem;
  border-top: 1px solid #e8e8e8;
  margin-bottom: -.6rem;
  
  .directions-link {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 1rem 0;
    
    &:after {
      margin-left: auto;
      @extend %icon;
      @extend .icon-external-link:before;
    }
  }
  
  &:empty {
    display: none;
  }
}



.iw-item-link {
  display: block;
  overflow: hidden;
  .img-wrap {
    float: left;
  }
}

.iw-place {
  > h1 {
    background: #272727;
    background-size: cover;
    background-position: 20% 50%;
    padding: 3rem 0 2rem;
    color: #fff;
    font-size: 1.7rem;
  }
}

.iw-place-items {
  $gutters: .4rem;
  padding: $gutters*2;
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutters;
  margin-right: -$gutters;
  //padding-bottom: 2rem;
  
  .iw-item {
    
    font-size: 1.2rem;
    background: #fff;
    position: relative;
    padding: 1.6rem .8rem .8rem;
    border: 1px solid #E0DFDC;
    border-style: none none solid;
    
    //border: $primaryBorder;
    margin: $gutters;
    width: 50%;
    max-width: calc(50% - #{$gutters}*2);
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    
    h1 {
      font-size: 1.3rem;
      padding-bottom: 0;
    }
    
  }
  img {
    //width: 40%;
  }
}

//todo: Bad rule for arrow styling
.si-pointer-bg-top {
  border-top-color: #fff !important;
}

.iw-item-view {
  
  
  .iw {
    padding-bottom: 0;
  }
  .iw-item {
    background: #fff;
    margin: 0;
    padding: 0.6rem;
  }
  .img-wrap {
    height: 7rem;
    padding: 0;
  }
}
