
// default table styling -----------------------// {

table {
  //margin-bottom: 3rem;

  @include tableStyles;
}
th, td {
  text-align: left;
  &.number {
    text-align: right;
    padding-right: 2rem;
  }
  &.icon {
    text-align: center;
    width: 0;
    white-space: nowrap;
  }
}
th {
  font-family: $secondaryFont;
  color: $headerColor;
  text-transform: uppercase;
  font-weight: 500;
  padding: .6rem;
}
td {
  padding: .4rem .6rem;
  &.number {
    white-space: nowrap;
  }
  &.icon {
    > a {
      overflow: hidden;
      width: 2em;
      vertical-align: middle;
      text-indent: 100%;
      position: relative;
      display: inline-block;
      &:before {
        text-indent: 0;
        position: absolute;
        left: 0;
        width: 100%;
      }
      > input {
        position: absolute;
        overflow: hidden;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
        z-index: 2;
      }
    }
  }
}
thead {

}
tbody {
  > tr {

  }
  > tr:first-child {
    > td {
      //padding-top: 1rem;
    }
  }
  > tr:last-child {
    //border-bottom: none;
  }
}

tfoot {

}

.table-compressed-borderless {
  tbody {
    > tr {
      border-bottom: none;
    }
    > tr:first-child {
      > td {
        padding-top: 1rem;
      }
    }
    > tr:last-child {
      border-bottom: none;
    }
  }

  tfoot {

  }
}

@media #{$mediaSmall} {
  th,td {
    padding: .6rem 0.2rem;
  }
  td {
    //font-size: 1.2rem;
  }
  th {
    font-size: 1.5rem;
  }
}

.order-table {
  @include orderTableStyles;
}