.welcomeText {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 300;

  &-inner {
    width: 80%;
    max-width: 120rem;
    margin: 1.3rem auto 2.7rem;
  }

  @include respondTo($mediaMedium) {
    font-size: 2rem;

    &-inner {
      width: calc(100% - 2rem);
    }
  }

  @include respondTo($mediaSmall) {
    font-size: 1.8rem;

    &-inner {
      width: 100%;
    }
  }
}
