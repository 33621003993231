
html.js { overflow-x: hidden;}

.splash-content {
  margin: auto;
}

a, button, #{$textInputSelectors} {
  $a: .1s;
  $b: .35s;
  transition: background-color $b, color $b, border-color $b/2;
  &:hover {
      transition: background-color $a, color $a, border-color $a;
  }
}

.J {
  display: none !important;
}


// Dropdown --------------------------/

.dropdown {
  position: relative;
}
.dropdown-content {
  display: none;

  .dropdown_active & {
    display: block;
  }

  .measure-height {
    position: absolute;
    height: 100%;
    width: 1px;
    top: 0;
    left: 0;
  }
}

.dropdown-toggle {
  cursor: pointer;
}

// Dropdown -|

// Related Products -------------------------/

.sliding-products-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-width: 100%;
  overflow: hidden;
}

.related-products-list {
  flex-wrap: nowrap;
}

// Related Products -|


// Product list -------------------/

.product-list-primary-layout {

  > .product-item-wrapper {
    flex: 20%;
    max-width: 20%;
    min-width: 20%;
  }

  @media (max-width: 1300px) {
    > .product-item-wrapper {
      flex: 25%;
      max-width: 25%;
      width: 25%;
      min-width: 25%;
    }
  }
  @media (max-width: 1000px) {
    > .product-item-wrapper {
      flex: 33.3333%;
      max-width: 33.3333%;
      min-width: 33.3333%;
    }
  }
  @media (max-width: 720px) {
    > .product-item-wrapper {
      flex: 50%;
      max-width: 50%;
      min-width: 50%;
    }
  }
}

// Sliding Items ////////////////////////////

.sliding-products-section {
  .product-list {
    flex-wrap: nowrap !important;

    > .product-item-wrapper {
      flex: 18%;
      max-width: 18%;
      min-width: 18%;
    }

    @media (max-width: 1300px) {
      > .product-item-wrapper {
        flex: 22.5%;
        max-width: 22.5%;
        min-width: 22.5%;
      }
    }
    @media (max-width: 1000px) {
      > .product-item-wrapper {
        flex: 30%;
        max-width: 30%;
        min-width: 30%;
      }
    }
    @media (max-width: 720px) {
      > .product-item-wrapper {
        flex: 45%;
        max-width: 45%;
        min-width: 45%;
      }
    }

  }
}

.sliding-nav-wrapper {
  //margin-top: -2.4rem;
  display: none;
  margin-top: 2rem;
  margin-bottom: 1.8rem;
}

.slit-prev,
.slit-next {
  //width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111 !important;

  padding: 0 1em;
  &:before {
    color: inherit;
    padding: 0 !important;
    @extend %icon;
  }
}
.slit-next {
  &:before {
    @extend .icon-chevron-thin-right;
  }
}
.slit-prev {
  &:before {
    @extend .icon-chevron-thin-left;
  }
}

.slit-nav-pages a {
  position: relative;
  width: 20px;
  height: 20px;
  display: block;

  &:after {
    box-sizing: border-box;
    background: #696868;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform .3s,background-color .9s;
    transform: scale(0.3);
    border-radius: 50%;
  }
  &:hover {
    &:after {
      //box-shadow: 1px 2px 10px -4px rgba(#000,1);
      //transform: scale(1);
    }
  }
  &.active {
    &:after {
      background: #ffffff;
      transform: scale(0.6);
      box-shadow: 2px 2px 6px -4px black;
    }
  }
}

//sliding items -|

.item-place {
  font-style: italic;
  position: relative;
  z-index: 2;
  line-height: 1.1;
  @extend .hvr-icon-fade-and-down;
}

.item-link {
  color: inherit;
  &:after {
    @include pseudoFill;
    z-index: 1;
  }
}

// Product list -|


.dropzone {
  border: 3px solid #ebebeb !important;
  border-radius: 0;
  background: #ffffff !important;
  margin-bottom: 1.6rem;
  color: #232323;

  &:hover {
    background: #fff !important;
  }

  i {
    color: $themeColor1;
    font-size: 3rem;
    vertical-align: middle;
  }
  p, span { color: inherit !important;}
  h2 {
    font-size: 2.8rem !important;
    font-weight: 400 !important;
    font-family: $secondaryFont !important;
    color: #b9b9b9 !important;
  }
}

// Form Page -|


// Modal -----------------/

.modal-outer {
  padding: 4rem;
  @media #{$mediaSmall} {
    padding: 4rem 0;
  }

  &.loader {
    .modal-close {display: none;}
    .modal {
      margin-top: 0;
    }
  }

}

.modal {
  max-width: 700px;
  position: relative;

  @media #{$mediaLarge} {
    padding: 2rem 7rem 5rem 7rem;
    @media (max-width: 1350px) {
      //flex-basis: 90%;
      //min-width: calc(100% - 5rem);
    }
    @media (min-width: 1600px) {
      //flex-basis: 82%;
      //min-width: initial;
    }
  }

  @media #{$mediaMedium} {
    padding: 2rem 6rem 5rem 6rem;
    //flex-basis: 80%;
    //min-width: calc(100% - 5rem);
  }
  @media #{$mediaSmall} {
    padding: 2rem;
    //width: calc(100% - 1.5rem);
  }

  .modal-close {
    position: absolute;
    background: $bgColor1;
    color: #000;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #fff;
      background: $themeColor1;
    }
    &:before {
      @extend %icon;
      @extend .icon-close:before;
      font-size: 2rem;
      padding: 0 !important;
    }
    top: 0;
    right: 0;
  }

}

// Modal -|


// Sticky

.sticky-constrain {
  position: relative;
}


// severity --------------//

p, i , em, strong, li, span {
  &.warning {
    color: $warningColor;
  }
}




// TO TOP ---//

#toTop {
  display: block;
  background: rgba($themeColor1, 1);
  color: #fff;
  position: fixed;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  text-align: center;
  padding: 0 1rem 1rem;
  @extend .hvr-icon-fade-and-up;
  i {
    display: inherit;
    font-size: 3em;
    line-height: 1;
    &:before {
      padding: 0;
      display: inherit;
    }
  }
  .text {
    text-transform: uppercase;
    margin-top: -0.4em;
  }
}

// Edit product

// single form

.single-form {
  input {
    border-right: none;
  }
  .btn {
    padding: .6em;
    min-height: 100%;
    min-width: 3em;
  }
  i {
    font-size: inherit !important;
  }
}


// CRUMB ----------------------------------------/

  .crumb {

    display: none;
    color: #111;
    margin-top: 1rem;
    font-size: 3rem;
    margin-bottom: 1rem;
    line-height: 1;
    font-family: $secondaryFont;
    font-style: italic;

    a, span {
      padding: 0.2em 0.1em 0.2em 0.2em;
    }
    * {
      font: inherit;
      display: inline-block;
    }
    a {
      color: inherit;
      &:after {
        content: ' »';
        font-size: 1.3em;
        font-weight: 400;
      }
      :hover {
        &:after {
          color: $textColorDark2;
        }
      }
      &:last-child:after {
        content: '';
      }
    }
  }


// CRUMB -|


[type="checkbox"]{

  +label {
    position: relative;
    &:before {
      //background: transparent !important;
    }
    &:after {
      @extend %icon;
      @extend .icon-check:before;

      position: absolute;
      left: 0.6rem;
      font-size: 2.1rem;
      top: 50%;
      margin-top: -0.2rem;
      transform: translateY(-50%) scale(0);
      transition: transform .15s;
      display: block;
      opacity: 0;
    }
  }

  &:checked {
    +label {
      &:after {
        opacity: 1;
        transform: translateY(-50%) scale(1);
      }
    }
  }
}


// Attribute UI ------------------------------------------//

.alphabet-list {
  position: relative;
  overflow: hidden;

  &:before {
    content: attr(data-letter);
    font-weight: 600;
    padding: 1rem;
    position: absolute;
    left: 0;
  }

  li {
    display: flex;
    width: 100%;
    flex: 100%;
    padding-left: 2.5em;
    border-bottom: 1px solid #efefef;
  }
}

.tree-select {

  .tree-branch {
    > ul {
      display: none;
    }
    &.active > ul {
      display: block;
    }
  }

  li {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 100%;
    border-bottom: 1px solid #efefef;
  }
  ul {
    padding-left: 1em;
    width: 100%;
    flex: 100%;
    border-top: 1px solid #efefef;
    > li:last-child {
      border-bottom: none;
    }
    //&:last-child {
    //  border-top: none;
    //}
  }
}

.attribute-ui {

  button {
    //font-size: 1.5rem;
  }
  .selected-attrs {
    font-weight: 300;
  }
  .single-form {
    .form-control {
      //position: relative;
      position: static;
    }
  }

  //h2 {
  //  font-size: 1.5rem;
  //  font-weight: 600;
  //}

  .type-filter-wrapper {
    margin-bottom: 2rem;
  }

  .scroll-outer {
    padding-right: 2rem;
  }

  .scroll-inner {
    max-height: 26rem;
  }

  .scroll-track {
    //background: #f3f3f3;
    right: 0rem;
    //opacity: 1;
    //width: 2px !important;
  }

  .scroll-handle {
    //background: #000;
  }


  ul {
    > li {
      display: flex;
      border-bottom: 1px solid #efefef;
      label {
        display: block;
      }

      [type="checkbox"],[type="radio"] {
        &+ label {
          padding: 1rem .4rem;
          flex: 1;
        }
      }

    }
    &:last-child {
      > li:last-child {
        border-bottom: none;
      }
    }
  }


}


// Attribute UI -|


// Quick search -----------------------//


.search-outputs {
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0;
  margin-top: 1rem;
  z-index: 10000;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.21);
  padding: 2rem;
}
.quick-search {
  .scroll-inner {
    max-height: calc(100vh - 24rem);
  }
}
.quick-search-summary{
  padding: 1rem 0;
}
.quick-search-results {
  padding-bottom: 1rem;
  .product-list {
    flex-direction: row;
  }
  .product-item-wrapper {
    //max-width: 50%;
    //flex: 50%;
    @media #{$mediaMediumUp} {
      max-width: 33.33%;
      flex: 33.33%;
    }
    @media #{$mediaLarge} {
      max-width: 25%;
      flex: 25%;
    }
    @media #{$mediaSmall} {
      max-width: 100%;
      flex: 100%;
    }
  }
  .item-header {
    h1 {
      top: auto;
      left: auto;
      position: relative;
      max-width: 13rem;
      right: -0.3px;
      background: none;
      padding: 0;
      color: #333;
      margin-bottom: 0.2em;
    }
  }
  .item-image-wrap {
    max-width: 10rem;
    height: 10rem;
    min-width: 10rem;
    min-height: 100%;
  }
  .item-footer {
    padding-top: 0.4em;
  }
  .item-place {
    padding-top: .4em !important;
  }
}

// Quick search -|

// flyout ----------//

.flyout {
  display: none;
  position: fixed;
  top: 0;
  background: #fff;
}

// flyout -|



.si-content-wrapper {
  padding: 0;
}

// MAP ///////////////////////////

.iw-scroll {
  max-height: none;
}

.map {
  height: 60vh;
}

.clear-val {
  position: absolute;
  background: none;
  width: 4rem;
  bottom: 0;
  right: 0;
  margin: 0;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: none;
    color: inherit;
  }
  &:before {
    @extend %icon;
    @extend .icon-cross;
    padding: 0 !important;
  }
}

.modal-map {
  .modal-inner {
    height: 90%;
    width: 90%;
  }
  .modal-content {
    width: 100%;
    height: 100%;
    border: 8px solid #fff;
  }
  .get-geo-map {
    width: 100%;
    height: 100%;
  }
}
.map-controls {

  .zoom-controls {
    position: absolute;
    z-index: 999;
    justify-content: center;
    top: 1.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .control-btn, .group-label {
    display: block;
    padding: 1rem 1.2rem;
    background: rgb(255, 255, 255);
    border-radius: 2px;
    margin: .2rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.3rem;
  }
}


// Scroll Nav ////////////////////////////////////////////////////////////////////////////////////

.scroll-nav {

  transition: opacity .6s;
  text-align: right;
  display: flex;
  z-index: 999;
  background: #fff;
  justify-content: center;
  width: 100%;

  &.stuck {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
  }
  &.stuck {
    opacity: 1;
  }

}
.scroll-nav-container {
  position: relative;
}

.scroll-nav-list {

  font-size: 1.6rem;
  display: flex;

  > li {

    cursor: pointer;
    > a {

      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit !important;
      transition: height .2s .1s, color .3s, background .3s;
      height: 4.6em;
      padding: 0 1.8rem 0 1.4rem;

      &:hover {
        color: currentcolor;
      }

      @media #{$mediaSmall} {

        color: transparent !important;
        overflow: hidden;
        width: 3.6em;
        justify-content: flex-start;

        &:before {
          color: $textColor !important;
          padding: 0;
        }
      }

    }

    &.active {
      >  a{
        background: $bodyBg;
      }
      &:after {
        //color: $textColor !important;
        //background: $themeColor1;
      }
    }

    .stuck & {
      > a {
        height: 3.1em;
      }
    }

    &.images,
    &.article,
    &.details,
    &.artist,
    &.location,
    &.related {
      a:before {
        @extend %icon;
        padding: 0;
      }
    }

    &.images a:before {@extend .icon-images:before;}
    &.article a:before {@extend .icon-info:before;}
    &.details a:before {@extend .icon-list:before;}
    &.artist a:before {@extend .icon-palette:before;}
    &.location a:before {@extend .icon-location:before;}
    &.related a:before {@extend .icon-flow-tree:before;}


  }
}



// Scroll Nav -|


.form-control_swatch {
  position: relative;
}


































