html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font: 62.5%/1.5 sans-serif;
  height: 100%;
}
html, body {
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}
html * {max-height:1000000px;}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: unset;
  width: 100%;
}

body {
  min-height:100%;
  font-size: $bodyFontSize;
  font-family: $primaryFont;
  color: $textColor;
  position: relative;
}
img {
  //border: none;
}
input, textarea, label, select, button {
  font-family: $primaryFont;
  @include appearance(none)
}
button {
  cursor: pointer;
  background: none;
}
iframe {
  display: block;
}
label {
  display: block;
  text-align: left;
}

.main { min-height: 100%; }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
}

main, article, section, form, fieldset, nav, figure, aside {
  display: block;
}
ul,li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  color: currentcolor;
  text-decoration: none;
  &:visited {
    color: currentcolor;
  }
}

p {
  margin: 0 0 1em;
  font-size: 1em;
}
h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}







