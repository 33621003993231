

// 00. Global color and style  -------------------------------------------------------------------------------///
$bodyBg: #eef0f1;

$themeColor1: #373737;
$themeColor2: #d4d4d4;

$themeColor1Lighter: lighten($themeColor1, 5%);
$themeColor2Lighter: lighten($themeColor2, 5%);

$themeColor1Darker: darken($themeColor1, 5%);
$themeColor2Darker: darken($themeColor2, 5%);

$bgColor1: #ededed;
$bgColor2: #c8c2b6;

$bgDark1: #808080;
$bgDark2: #3d3d3d;
$bgDark3: #232323;

/////

$darkBgColor: #211f1f;

$textColorDark: #000;
$textColorDark2: #111;

$textColor: #111;
$textColorLight: #636363;
$textColorLighter: #949292;
$textColorAttention: #f33c54;
$warningColor: #f33c54;
//$successColor: #82eba7;
$successColor: #5ee695;
$actionColor: #4b4947;
$actionColor: $bgDark3;
$actionColor: #262d31;
$confirmColor: #587f95;
//$actionColor: #73a5c1;

$headerColor: $textColorDark;
$linkColor: $themeColor1;
$mainHeaderBg: #fff;
$mainFooterBg: $bgDark2;
$mainLogoWidth: auto;

$primaryBoxShadow: 0 0 5px rgba(0, 0, 0, 0.19);
$primaryBorderColor: #ebebeb;
$darkBorderColor: #9c9c9c;
$primaryBorder: 1px solid $primaryBorderColor;
$darkBorder: 1px solid $darkBorderColor;

$mainContentPadding: null;
$mainContentTopPadding: 6rem;
$mainContentBottomPadding: 6rem;

$inputBackground: #fafafa;
$inputBorderColor: #ebebeb;


.fill-bg-1 {
  background: $bgColor1;
}
.fill-bg-2 {
  background: $bgColor2;
}
.fill-theme-1 {
  background: $themeColor1;
  color: #fff;
}
.fill-theme-2 {
  background: $themeColor2;
  color: #fff;
}
.fill-dark1 {
  background: $bgDark1;
  color: #fff;
}

$primaryBoxShadow: 0 0 5px rgba(0, 0, 0, 0.19);
$shadow1: 0 1px 2px 0 #484848;
$shadow2: 0 0 33px rgba(0, 0, 0, 0.21);

$SM-triggerBg: $themeColor1;
$SM-headerBg: $themeColor1;

// 01. Typography  --------------------------------------------------------------------------------------///


$primaryFont: 'Barlow', sans-serif;
$secondaryFont: 'Barlow', sans-serif;

$primaryFont: 'Assistant', sans-serif;
$secondaryFont: 'Assistant', sans-serif;

$thirdFont: 'itw', sans-serif;

$bodyFontSize: 1.4rem;

$iconFont: 'icomoon';



h1, h2, h3, h4  {
  font-family: $secondaryFont;
  font-weight: 500;
}
h2, .h2 {
  font-size: 3.6rem;
}

$headerColorDefault: $textColorDark;
$smallPrintSize: 1.3rem;
$smallPrintMobileSize: 1.2rem;
$smallPrintColor: $textColorLight;


// 02. Grid and breakpoints  -------------------------------------------------------------------------------///


//Set total amount of columns
$gridColsTotal: 12;

$mediaMediumStart: '721px';
$mediaMediumEnd: '1020px';

//Define all breakpoints
$mediaSmall: '(max-width: #{$mediaMediumStart})';
$mediaMediumBelow: '(max-width: #{$mediaMediumEnd})';
$mediaMedium: '(max-width: #{$mediaMediumEnd}) and (min-width: #{$mediaMediumStart})';
$mediaMediumUp: '(min-width: #{$mediaMediumStart})';
$mediaLarge: '(min-width: #{$mediaMediumEnd})';

////Define grid breakpoints
//$gridBreakpoints: (small medium large);

//Map defined grid breakpoints
$gridBreakpoints: (
    small: $mediaSmall,
    medium: $mediaMedium,
    large: $mediaLarge
);

//Define gutter sizes
//$guttersSizes: (
//  xxxxn
//  xxxn
//  xxn
//  xn
//  n
//  nx
//  nxx
//  nxxx
//);

$guttersSizes: (
  hairline
  thin
  narrower
  narrow
  normal
  wide
  wider
);

//Map defined gutter sizes
//$guttersMap: (
//    xxxxn: .1rem,
//    xxxn: .2rem,
//    xxn: .5rem,
//    xn: 1.2rem,
//    n: 2rem,
//    nx: 3.5rem,
//    nxx: 4rem,
//    nxxx: 4.5rem
//);


$guttersMap: (
    hairline: .1rem,
    thin: .2rem,
    narrower: .5rem,
    narrow: 1.2rem,
    normal: 2rem,
    wide: 3rem,
    wider: 4.5rem,
);


// 03. Main navigation  -------------------------------------------------------------------------------///

@mixin mainNavStyles { // outer NAV tag
  flex: 1;
}

@mixin mainNavListStyles { //ul in level 1
  font-family: $secondaryFont;
}
@mixin navLevel1Styles { // li in level 1
  //&:first-child > a {padding-left: 0;}
  display: flex;
  > a {
    white-space: nowrap;
    align-items: center;
    display: flex;
    transition: all 0.2s;
  }
  @media #{$mediaMedium} {
    > a {
      align-self: stretch;
      padding: 1rem;

    }
  }
}

//Main nav level 1
$navFontSize: 1.7rem;
$navFontWeight: 300;
$navColor: #fff;
$navHoverColor: $themeColor1;
$navHoverBackground: #fff;
$navCaps: null; // text-transform
$navBg: null;
$navHoverBg: null;
$navItemPadding: 1rem 2rem;
$navItemMargin: 0;

$navHoverTextDecoration: underline;

$navMediumFontSize: 1.4rem;

//Mobile main nav

$mobileNavBackground: #fff;
$mobileNavShadow: null;
$mobileNavColor: $themeColor1;
$mobileNavActiveColor: null;
$mobileNavCaps: null;
$mobileNavFontSize: 1.5rem;
$mobileNavPadding: 1.3rem .3rem;
$mobileNavBorder: none;

@mixin mobileNavStyles {
  font-size: $mobileNavFontSize;
  > li {
    > a {
      padding: $mobileNavPadding;
      color: $mobileNavColor;
      text-transform: $mobileNavCaps;
      border: $mobileNavBorder;
      width: 100%;
      &:hover {
        background: none;
        color: $themeColor1;
      }
    }
  }
}


//Main aside nav
$mainAsideNav: true;
$mainAsideNavFontSize: 1.3rem;
$mainAsideNavItemMargin: .5rem;
$mainAsideNavItemPadding: .5rem;
//$mainAsideNavColor: $secondaryColor3;
$mainAsideNavColor: $navColor;
$mainAsideNavHoverColor: $navHoverColor;

//Mobile main aside nav
$mobileMainAsideNav: true;
$mobileMainAsideNavMargin : 0 0 .6rem;
$mobileMainAsideNavFontSize: 1.2rem;
$mobileMainAsideNavItemMargin: 0;
$mobileMainAsideNavItemPadding: 1rem 1.3rem 1rem 0;
$mobileMainAsideNavColor: $themeColor2;


// 04. Forms  --------------------------------------------------------------------------------------///



$inputCheckMargin: -0.22em .4em 0 0;
$inputCheckBorder: null;
$inputCheckedColor: $themeColor1;

@mixin selectIcon {
  position: absolute;
  top: calc(50% - 0.7em);
  right: .7em;
  font-size: 1.3em;
  color: $textColor;
  font-family: icomoon;
  @extend .icon-triangle-down:before;
}

#{$btnSelectors} {
  @include btnFactory(
          $textTransform: uppercase,
          $background: $themeColor1,
          $fontSize: 1.8rem,
          $height: 5.5rem,
          $fontWeight: 500,
          $padding: 0em 1em
  );
  @media #{$mediaSmall} {
    font-size: 1.4rem;
    height: 4.9rem;
    $padding: .8em 1em
  }
}
.btn-theme2 {
  #{$btnSelectors} {
    @include btnFactory(
            $textTransform: uppercase,
            $background: $themeColor2,
            $fontSize: 1.8rem,
            $fontWeight: 500,
            $padding: 1em 1.7em
    );
    @media #{$mediaSmall} {
      font-size: 1.4rem;
      $padding: .8em 1em
    }
  }
}
.btn-theme3 {
  #{$btnSelectors} {
    @include btnFactory(
            $textTransform: uppercase,
            $background: #fff,
            $hoverBackground: $themeColor1,
            $color: $themeColor1,
            $fontSize: 1.8rem,
            $fontWeight: 500,
            $padding: 1em 1.7em
    );
    @media #{$mediaSmall} {
      font-size: 1.4rem;
      $padding: .8em 1em
    }
  }
}

@include formFactory(
        $inputHeight: 100%,
        $inputPadding: 0 1em,
        $inputFontWeight: 500,
        $inputBorder: 1px solid #ebebeb,
        $inputBackground: #fafafa,
        $booleanCheckColor: $themeColor2
);


// 05. Product Item --------------------------------------------------------------------------------------///


@mixin itemHeader {
  width: 100%;
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.4em;
  }
}

@mixin item {

  padding: 1.8rem 0 2.6rem;
  background: #fff;
  position: relative;
  border: 1px solid #E0DFDC;
  border-style: none none solid;

  .item-place {
    padding-top: 1rem;
    a {
      i {
        color: #000;
        font-size: 1.4rem;
      }
      text-transform: uppercase;
      color: $themeColor1;
      font-weight: 600;
      font-size: 1.1rem;
      &:hover {
        color: $themeColor1;
      }
    }
  }

  @media #{$mediaSmall} {
    padding: 0.7rem 1rem 1.2rem;
  }

  &:hover {
    .item-image-wrap {
      img {
        filter: grayscale(.1) contrast(1.1) brightness(1.04) saturate(1);
      }
    }
  }

  .slit-overflow & {
    &:hover {
      .item-image-wrap {
        img {
          filter: grayscale(0) contrast(1) brightness(1) saturate(1);
          transform: none;
        }
      }
    }
  }
}

@mixin itemImageWrap {
  width: calc(100% - 2rem);
  margin-left: 1rem;
  position: relative;
  display: flex;
  >img {
    transition: filter .25s;
    filter: grayscale(0) contrast(1) brightness(1) saturate(1);
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  min-height: 17rem;
  @media #{$mediaSmall} {
    min-height: 9rem;
  }
}
@mixin itemTimer {
  position: absolute;
  top: 2rem;
  text-align: center;
  width: 100%;
  > .item-timer-content {
    background: #fff;
    display: inline-block;
    padding: .2rem 1rem;
    font-weight: 600;
  }
  .item-timer-definition {
    color: $themeColor1;
  }
  @media #{$mediaSmall} {
    > .item-timer-description {

    }
    > .item-timer-content {

    }
  }
}

@mixin itemContent {
  text-align: center;
  font-size: 1.3rem;
  padding: 1.4rem 0 0;
  .product-item-body {
    width: 100%;
    padding: 0rem 0 1.2rem;
    p {
      margin-bottom: 0;
    }
  }
  .split-list {
    >li {
      border-bottom: none;
      padding: 0.1em 0;
    }
  }
  @media #{$mediaMedium} {

  }
  @media #{$mediaSmall} {
    padding: 1.6rem 0rem 0rem;
    font-size: 1.2rem;
  }
}


@mixin itemFooter {
  display: none;
  align-self: flex-end;
  padding-top: 1rem;
  font-weight: 400;
  font-size: 1.4rem;

  .def, .val {
    display: table-cell;
  }
  .val {text-align: right;}

  > .item-footer-pre {

  }
  > .item-footer-content {

    > .price {

    }
    > .currency {

    }
    font-size: 2rem;
    @media #{$mediaLarge} {
      font-size: 2.2rem;
    }
  }
  @media #{$mediaSmall} {
    padding-top: 0rem;
    > .item-footer-pre {

    }
  }
}

/////////////////////////

@mixin itemVertical {

}

@mixin itemHorizontal {

}


// 05 b. Product Page  --------------------------------------------------------------------------------------///

@mixin productInfoSectionHeaderStyle {
  @extend .section-header;
  font-size: 2.3rem;
}
@mixin productInfoHeaderStyle {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}
@mixin productInfoTextStyle {
  font-size: 1.4rem;
  @media #{$mediaSmall} {
    font-size: 1.3rem;
  }
}
@mixin productInfoTableCellStyle {
  font-size: 1.5rem;
  padding: 0.9rem 0.5rem;
  @media #{$mediaSmall} {
    font-size: 1.3rem;
  }
}


// - Tables ----------------------------------------------------------//

@mixin tableStyles {
  .price {
    text-align: right;
  }
}

@mixin orderTableStyles {
  > tfoot {
    > tr {
      border-top: $primaryBorder;
      border-bottom: 2px solid $themeColor1;
      > td {
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
    }
  }
}










