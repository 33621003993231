
// FILTER -----------------------------------------------//


.filter-outer {
  padding-top: 3rem;
  padding-bottom: 1rem;
  position: relative;
  z-index: 90;
}
.art-filter {
  padding-top: 0;
  border-top: $primaryBorder;
}

.filter-inner, .cp {

  .dropdown-group {
    margin-bottom: 2.6rem;
  }

  .dropdown-toggle {
    padding: 1.6rem;
    background: #fff;
    //border: $primaryBorder;
    display: flex;
    align-items: center;
    color: $textColorDark;
    font-weight: 500;
    @media #{$mediaSmall} {
      padding: 1.3rem;
    }

    a {
      flex: 1;
      color: inherit;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:after {
      transition: transform .25s, color .25s;
      padding-left: 0.9rem;
      font-size: 1.9rem;
      @extend %icon;
      @extend .icon-triangle-down:before;
    }
  }

  .single-form {
    .btn {
      //background: #fff;
      //border: $primaryBorder;
    }
  }

  .text-input {
    height: 100%;
    > input {
      min-width: 20rem;
      background: #fff;
      border: $primaryBorder;
    }
  }

  .dropdown_active .dropdown-toggle {
    background: #fff;
    border-bottom-color: #fff;
    position: relative;
    z-index: 600;
    //padding-bottom: .7rem;
  }

  .dropdown-content {
    background: #fff;
    //border: $primaryBorder;
    box-shadow: 2px 3px 11px -1px rgba(0, 0, 0, 0.31);
    font-size: 1.4rem;
    font-weight: 400;
    position: absolute;
    top: calc(100% - 1px);
    left: -.2rem;
    z-index: 500;
    width: 100%;

    min-width: 40rem;
    padding: 1.6rem;

    @media #{$mediaSmall} {
      min-width: 96vw;
      max-width: 96vw;
    }

    .type-filter-wrapper {
      padding-bottom: 1rem;
      background: #fff;
    }

    .scroll-inner {
      max-height: 40vh;
    }

    .count {
      color: #c3c3c3;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
    }

    li {
      display: flex;

      [type="checkbox"],
      [type="radio"]{
        + label {
          padding: 1rem .4rem;
          flex: 1;
        }
      }
    }

    .split-by2 {
      //overflow: hidden;
      > li {
        //max-width: 50%;
        //flex: 50%;
        //background: #fff;
        //border-color: #efefef !important;
        //border-bottom: 1px solid;
        //margin-bottom: 1px;
        //&:nth-child(odd) {
        //  border-right: 1px solid;
        //}
        &:after {
          font-size: 1rem;
          content: 'splitmenot';
          color: #ccc;
          padding-top: 1em;
        }
      }
    }

    .full-width, .split-by2 {
      > li {
        min-width: 100%;
        border-bottom: 1px solid #efefef;
        &:last-child {
          border: none;
        }
      }
    }

  }

  .dropdown {
    .scroll-track {
      right: -1rem;
    }
    min-width: 12rem;
    position: relative;
    &.active {
      .dropdown-content {

      }
      .dropdown-toggle {
        &:after {
          //color: $themeColor1;
          transform: rotateX(180deg);
        }
      }
    }
  }
}
.f-mirror-val {
  [type="radio"] + label:before {
    //display: none;
  }
}

.filter-inner {
  [type="checkbox"]:checked+label:before, [type="radio"]:checked+label:before {
    box-shadow: none;
  }
};

.filter-group-count {
  display: none;
  color: $themeColor1;
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
}

.filter-summary {
  .summary-count {
    font-weight: 500;
    font-size: 1.6rem;
    padding-left: .2rem;
  }
  margin-bottom: 1rem;
}

.filter-summary-items {
  //text-align: center;
  //padding-left: 1.4rem;
}

.summary-group, .item-has-group {
  display: flex;
  flex-wrap: wrap;
}

.filter-summary-item-group {

  padding: .4rem 0;
  margin: 0 .4rem;
  display: inline-flex;
  flex-wrap: wrap;

  .summary-group-name,
  .summary-item {
    border: 1px solid transparent;
    background: #fff;
    flex: none;
    margin-right: -1px;
    margin-top: -1px;
    text-align: left;
    display: flex;
    height: 4.6rem;
    font-style: italic;
    align-items: center;
    cursor: pointer;

    &:hover {
      //border-color: $themeColor1;
      background: $themeColor1;
      color: #fff;
      &:before {
        color: inherit;
      }
    }
  }

  .summary-group-name {
    background: $bgColor1;
    color: $textColorDark;
    padding: 0.4rem 0.9rem 0.4rem 0.8rem;
    &:before {
      @extend %icon;
      @extend .icon-cross:before;
      vertical-align: middle;
      color: $themeColor1;
      padding-right: .2rem !important;
    }
  }

  .summary-item {
    border: 1px solid $bgColor1;
    padding: 0.4rem 1rem 0.4rem 0.3rem;
    color: $textColorDark;
    &:before {
      @extend %icon;
      @extend .icon-cross:before;
      vertical-align: middle;
      color: $themeColor1;
      padding-left: .3rem;
      padding-right: .2rem !important;
    }
  }

}

.filter-inner {
  .clr-pseudo {
    &:before {
      display: none !important;
    }
  }
  label {
    display: block;
  }
  input:checked {
    & + label {
      background: #fafafa ;
    }
  }

  .text-input > input,
  .dropdown-toggle{
    border: none;
    font-weight: 600;
    font-size: 1.4rem;
    height: 6.8rem;
  }
}
