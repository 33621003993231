


//ALL PAGES

.product-list-section {
  position: relative;
  z-index: 1;
  padding-bottom: 6rem;
  padding-top: 1rem;
  min-height: 32rem;
}
.pager-link {
  font-size: 2rem;
  &.prv-page {@extend .hvr-icon-fade-and-left;}
  &.nxt-page {@extend .hvr-icon-fade-and-right;}
}


// Index ---------------------------------------------/


.index-tags {

  display: flex;
  padding: 2rem 0 3rem;
  max-width: 100%;
  width: 100%;
  font-weight: 300;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .tag-wrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  .tag {
    flex: none;
    margin: .2rem;
    background: #fff;
    color: #000 !important;
    border: $primaryBorder;
    padding: 1.2rem 1.4rem;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.5rem;
  }
}




// Article ---------------------------------------------/

.artwork-page {
  .cat-nav-list {
    padding: 0.9rem 0;
  }
}

.article-header {
  text-align: center;
  padding-top: 3.6rem;
  padding-bottom: 1.7rem;
  background: #fff;
  border-bottom: $primaryBorder;

  h1 {
    text-transform: none;
    font-size: 4vw;
    line-height: 1;
    margin-bottom: .22em;
  }

  .subheader {

    font-weight: 300;
    font-size: 1.5rem;
    display: inline-block;
    background: #fff;
    position: relative;
    z-index: 800;
    margin-bottom: 0;
    //top: 0.7rem;
    padding: 0rem 2.3rem 0.9rem;
    &:empty {
      visibility: hidden;
    }
    > a {
      color: $textColorLighter;
      text-transform: uppercase;
    }
  }
  @media #{$mediaSmall} {
    h1 {font-size: 2.8rem;}
  }


}

.sticky-wrapper {
  + .article-content-section,
  + .article-details-section {
    padding-top: 6rem;
  }
}

// content

.article-content-inner, .article-details-inner {
  @media #{$mediaLarge} {
    font-size: 1.8rem;
  }
}

.article-content-section  {
  padding-bottom: 4rem;
}

.article-first-content-section {
  padding-top: 6rem;
}

.article-content-inner {

  flex: none;
  width: 100%;
  max-width: 104rem;
  font-size: 1.8rem;
  padding: 1em 3em;

  @media #{$mediaSmall} {
    padding: 0 2rem;
  }
  .copy {
    font-size: 1.4rem;
    font-style: italic;
  }
}

.article-content-text {
  position: relative;
  margin-bottom: 0;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
  border-color: #bbb;
  padding: 2.2em 0 2em 0;

  h2 {
    margin-bottom: .7em;
    &.text-center {
      display: none;
    }
  }
}
.addthis-widget {
  justify-content: center;
  display: flex;
  padding-top: 2.7rem;
  margin-bottom: -2.7rem
}
.article-details-section {
  padding-bottom: 5rem;
}

.article-details-inner {

  flex: none;
  width: 100%;
  background: #fff;
  border-radius: 1rem;
  padding: 2rem 4rem;
  max-width: 70rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  @media #{$mediaLarge} {
    font-size: 1.6rem;
  }
  @media #{$mediaSmall} {
    padding: 0 1.2em;
  }
}
.article-data {
  min-width: 100%;
}

.article-data {
  @extend %split-list;
  > li {
    line-height: 1.5;
    padding: 1.2rem 0;
    display: flex;
    border-bottom: 1px dotted $primaryBorderColor;
    &:last-child {border-bottom: none;}
    a {
      color: inherit !important;
      display: inline-flex;
      align-items: center;
      position: relative;
      text-decoration: underline;
    }
    .external-link {
      &:after {
        @extend %icon;
        @extend .icon-external-link:before;
        position: relative;
        right: -0.4rem;
        top: -0.15em;
        font-size: 1.2rem;
      }
    }
    > * {
      &:first-child {
        font-weight: 600;
      }
      &:last-child {
        padding-left: 5rem;
        flex: 1;
      }
    }
  }
}

.article-data-tags {

  display: flex;
  padding: 0 0 3rem;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .tag-wrapper {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tag {
    flex: none;
    margin: .2rem;
    background: #f1f1f1;
    color: #000 !important;
    border: $primaryBorder;
    padding: 0.7rem 1rem;
    border-radius: 5px;
  }
}

.article-copy {
  text-align: justify;
  text-align-last: center;
  font-size: 1.3rem;
  font-style: italic;
  color: $textColorLight;
}

//related todo: own selector

#map {
 + .sliding-products-section {
   //position: relative;
   //z-index: 10;
   //display: flex;
   //flex-wrap: wrap;
   //padding-top: 0;
   //> h1 {
   //  font-size: 2.6rem;
   //  padding: 0.2em 0.9em;
   //  margin-top: -0.4em;
   //  margin-bottom: 1em;
   //  background: $bgColor1;
   //  margin-left: auto;
   //  margin-right: auto;
   //}
 }
}





// INDEX ---------------------------------------------/
//
//
//.index-header {
//  padding: 3rem 0;
//  h1 {
//    font-size: 4.7rem;
//  }
//  @media #{$mediaSmall} {
//    h1 {
//      font-size: 2.2rem;
//    }
//  }
//}
//
//.index-hero {
//  //background-image: url(../resources/images/banner/friends-shopping-01.jpg);
//  background-image: url("http://v.img.com.ua/b/orig/b/0a/9105572fed0292b0ff1adcc5333570ab.jpg");
//  background-position: 50% 26%;
//  padding-top: 5rem;
//  //padding-bottom: 18rem;
//  //margin-bottom: 2rem;
//  min-height: 23rem;
//  max-height: 40rem;
//  height: 25vw;
//
//  header {
//    color: #fff;
//    font-size: 5rem;
//  }
//  h1 {
//    position: absolute;
//    left: 0;
//    top: 6rem;
//    font-size: 3rem;
//    max-width: 36%;
//  }
//
//  @media (max-width: 990px) {
//    h1 {
//      top: 3rem;
//      font-size: 2rem;
//    }
//  }
//
//}
//
//.index-promo-boxes-01 {
//  position: relative;
//  background: #070604;
//  background: linear-gradient(to top, #070604, #282320);
//  margin-bottom: 5rem;
//  .promo-boxes-inner {
//    position: relative;
//    top: -3rem;
//    margin-bottom: -5rem;
//  }
//
//  .promo-box {
//    transition: transform .2s;
//    min-height: 22rem;
//    background-position: 20% 0;
//    @media #{$mediaSmall} {
//      min-height: 15rem;
//    }
//  }
//
//  &:after {
//    content: '';
//    position: absolute;
//    background: #fff;
//    width: 100%;
//    height: 3rem;
//    top: calc(100% - 3rem);
//    left: 0;
//    display: block;
//  }
//}
//
//.index-vendor-nav {
//  //display: none;
//  background: #fff;
//  padding: .6rem 1.6rem;
//  margin-bottom: 2rem;
//  border-color: $textColorDark2;
//  h2 {
//    color: $textColorDark2;
//  }
//}
//
//.inner-box {
//  padding: 2rem;
//  color: #fff;
//  h2  {
//    text-transform: uppercase;
//    color: #fff;
//    font-size: 2.4rem;
//    font-weight: 600;
//  }
//  a {
//    color: #fff;
//  }
//}
//
//.shop-nav-container {
//  background: $themeColor1;
//  margin-bottom: 1.2rem;
//
//  h2{
//    font-size: 5vw;
//    .icon-select-arrows:before {
//      font-size: .7em;
//    }
//  }
//}
//
//// INDEX END -|
//
//
//// SELLER GLOBAL -----------------------------------/
//
//
//// SELLER GLOBAL -|
//
//
//
//// VENDOR ---------------------------------------------/
//
//.vendor-hero {
//  background-image: url(../resources/images/banner/vendor-dummy-bw.jpg);
//  padding-top: 5rem;
//  padding-bottom: 16rem;
//  background-position: 50% 40%;
//
//  .bubble-wrap {
//    padding-bottom: 4rem;
//  }
//
//  .bubble {
//    //font-size: 3.5rem;
//
//    .vendor-info {
//      padding-top: .3em;
//      text-align: left;
//      font-size: 1.4rem;
//      i {
//        font-size: 4rem;
//        padding: 0 .3em;
//
//        &:before {
//          padding: 0;
//        }
//        transition: transform .15s ;
//      }
//      a {
//        padding-left: .2em;
//        color: #fff;
//
//        &:hover {
//          i {
//            transform: scale(1.2);
//          }
//          .link-text {
//            text-decoration: underline;
//          }
//        }
//      }
//    }
//
//    @media #{$mediaSmall} {
//      .bubble-inner {
//        font-size: 2.5rem;
//      }
//      .vendor-info {
//        padding-top: 1rem;
//        font-size: 1.25rem;
//        margin-left: -0.9rem;
//        i {
//          font-size: 3.2rem;
//        }
//      }
//
//      .bubble-wrap {
//        padding-left: 1rem;
//        padding-right: 1rem;
//      }
//
//      .link-text {
//        //display: none;
//      }
//    }
//  }
//
//
//}

// VENDOR END -|





















