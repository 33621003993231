
// Scroll --------------------------/

.scroll-track {
  opacity: .3;
  transition: opacity .25s, width .1s;
  position: absolute;
  top: 0;
  right: -.4rem;
  z-index: 1;
  width: 2px;
  //height: calc(100% - 24px);
  height: 100%;
  background: #e8e8e8;
  &:after{
    position: absolute;
    content: '';
    width: 20px;
    height: 100%;
    left: -11px;
    top: 0;
    display: block;
  }
  &:hover {
    width: 6px;
    opacity: 1;
    //cursor: pointer;
  }
}
.scroll-host:hover + .scroll-track {
  opacity: 1;
}
.scroll-inner {
  max-height: 400px;
}
.scroll-outer {
  //position: static !important;
}
.scroll-handle {
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 0px;
  background: #000;
  &:after {
    $s: 20px;
    position: absolute;
    content: '';
    width: calc(100% + #{$s});
    height: calc(100% + #{$s});
    top: -$s/2;
    left: -$s/2;
  }
  &:hover {
    cursor: default !important;
  }
}

// Scroll -|