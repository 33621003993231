
%split-list {
  > li {
    display: block;
    @extend %clearfix;
    > *:first-child {
      float: left;
    }
    > *:last-child {
      float: right;
      text-align: right;
    }
  }
}

.split-list {
  @extend %split-list;
}

.split-list-3-up {
  > li {
    border-bottom: $primaryBorder;
    padding: .6em 0;
    overflow: hidden;
    display: flex;
    > span:first-child {
      & + span {
        flex: 1;
        padding-left: .5em;
        padding-right: .5em;
        text-align: center;
        & + span {
          text-align: right;
        }
      }
    }
  }
}