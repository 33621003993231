

.customSelect-inner {
  position: relative;
}

.customSelect-options{

  position: absolute;
  min-width: 100%;
  top: 100%;
  left: 0;
  display: none;
  overflow: hidden;

  .customSelect_open & {
    display: block;
  }
}

.customSelect-option {
  > span {
    pointer-events: none;
  }
}