// Cat nav -----------------------//


.cat-nav-outer {
  width: 100%;
  padding: 0;
  //margin-bottom: 3rem;
  background: #fff;
}
.cat-nav-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.cat-nav-list {

  font-size: 1.4rem;
  font-weight: 500;
  display: inline-flex;
  position: relative;
  margin: auto;
  font-family: $secondaryFont;
  font-style: italic;
  flex-wrap: nowrap !important;
  max-width: 100%;
  min-width: 100%;

  justify-content: center;
  .slit-overflow & {
    justify-content: initial;
  }

  $c: #000;

  > li {

    flex: none;
    text-align: center;

    > a {

      color: $textColorDark;
      flex: 1;
      width: 100%;
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 2rem 0rem;
      line-height: 1;

      i {
        display: block;
        margin-bottom: 0.16em;
        transition: color .2s, transform .2s;
        color: $c;
        font-size: 2.9em;
        line-height: 1;
        &:before {
          padding-right: 0;
        }
      }

      &:hover {
        i {
          transform: scale(1.2) rotate(7deg);
        }
      }
    }

    @media #{$mediaMediumBelow} {
      > a {
        flex-direction: column;
      }
    }
    @media #{$mediaMedium} {
      width: 11.5%;
      min-width: 8.5em;
      max-width: 10em;
    }
    @media #{$mediaSmall} {
      font-size: 1.3rem;
      width: 22.2%;
      max-width: none;
      min-width: 0;
      > a {
        padding: 1.8rem .4rem;
      }
    }
    @media #{$mediaLarge} {
      > a {
        padding: 2rem;
        i {
          margin-right: .2em;
        }
      }
    }

    &.active {
      a {
        > span {
          position: relative;
          &:after {
            content: '';
            width: 100%;
            position: absolute;
            display: block;
            height: 3px;
            background: #222;
            bottom: -6px;
          }
        }
      }
      a:hover {
        i {
          transform: none;
        }
      }
    }

    //&:last-child a {
    //  border-right: 1px solid #e0e0e0;
    //}

    &.active {
      span {
        //text-decoration: underline;
      }
    }
  }
}

.cat-nav-place-nav {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1rem;

  .select-element {
    font-style: normal !important;
    height: 4.8rem;
    padding-left: 3.1rem;
    //border: 2px solid #3d3d3d;
    border-radius: 3px;
    min-width: 15rem;
    max-width: 15rem;
    position: relative;
    border: none;
    background: transparent;

    &:before {
      display: block;
      font-size: 4.5rem;
      content: "\e922";
      left: 0;
      font-family: icomoon;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      display: none;
      font-size: 1.4rem;
    }

    select {
      background: transparent;
    }
  }
}

#flags_toggle {
  opacity: 0;
}
