

/* Icon Drop */

@mixin fadeAndGoAnimation($name, $direction, $value) {
  @keyframes #{$name} {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
      transform: translate#{$direction}($value);
    }
    51%,
    100% {
      opacity: 1;
    }
  }
}

@include fadeAndGoAnimation(fade-and-up, Y, 100%);
@include fadeAndGoAnimation(fade-and-down, Y, -100%);
@include fadeAndGoAnimation(fade-and-left, X, 100%);
@include fadeAndGoAnimation(fade-and-right, X, -100%);

.hvr-icon-fade-and-up {&:hover i {animation-name: fade-and-up}}
.hvr-icon-fade-and-down {&:hover i {animation-name: fade-and-down}}
.hvr-icon-fade-and-left {&:hover i {animation-name: fade-and-left}}
.hvr-icon-fade-and-right {&:hover i {animation-name: fade-and-right}}

.hvr-icon-fade-and-up,
.hvr-icon-fade-and-down,
.hvr-icon-fade-and-left,
.hvr-icon-fade-and-right {
  i {
    display: inline-block;
    transition: opacity .4s;
  }
  &:hover {
    i {
      opacity: 0;
      transition-duration: 0.3s;
      animation-duration: 0.4s;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
  }
}

/* Icon Drop */

@keyframes wobble-horizontal {
  16.65% {
    transform: translateX(6px);
  }
  33.3% {
    transform: translateX(-5px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}

.hvr-icon-wobble, %hvr-icon-wobble {
  i {
    display: inline-block;
  }
  &:hover, &:focus, &.active {
    i {
      animation-name: wobble-horizontal;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }
  }
}
