
.pager {
  height: 3.8rem;
  overflow: hidden;
  display: flex;
}

.pager_top {
  margin-bottom: 2rem;
}

.pager_bottom {
  margin-top: 2rem;
}

.pager-pageNav {
  display: flex;
  flex: 1;
}

.pager-pageNumbers,
.pager-pageSteppers {
  display: flex;
  flex: none;
}

.pager-pageSteppers {
  margin-right: auto;
}

.pager-pageNum,
.pager-step {
  transition: all .25s;
  font-weight: 600;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: none;
  margin-right: .2rem;
  padding: 0 2rem;
  box-shadow: inset 0px 0px 20px 0px rgba(0,0,0,0);

  background: #fff;
  border: 1px solid #eee;
  border-radius: 3px;

  &:active {
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.3);
    transition: all .07s;
    //background: #000;
    //color: #fff;
  }
}

.pager-pageNum {
  &_active {
    pointer-events: none;
    //background: var(--cp-darkColor);
    border-color: var(--cp-darkColor);
    //color: #fff !important;
  }
}

.pager-step_disabled {
  opacity: .6;
  pointer-events: none;
}
