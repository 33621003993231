// Header/NAV ----------------------------------------------------------//

html {
  @media #{$mediaSmall} {
    font-size: 54%;
  }
}

#main-home-link {

  //display: inline-block;
  //padding: 1rem 2.1rem 1.2rem;
  //margin-top: -0.1rem;
  //margin-bottom: -2rem;
  //position: absolute;
  //z-index: 999;
  //left: 50%;
  //transform: translateX(-50%);


  //background: #fff;

  //border-top: 1.2rem solid #fff;

  //&:after, &:before {
  //  content: '';
  //  width: 50%;
  //  height: 100%;
  //  position: absolute;
  //  top: 0;
  //}
  //&:before {
  //  left: 0;
  //  border-radius: 0 0 0 109%;
  //}
  //&:after {
  //  right: 0;
  //  border-radius: 0 0 109% 0;
  //}
  h1 {
    position: relative;
    z-index: 10;
    margin: 0;
    line-height: 0;
  }

  @media #{$mediaSmall} {
    //padding: 1.7rem 4rem 1.3rem 0.2rem;
  }
}

#main-logo {
  flex: none;
  height: 82px;
  position: relative;

  @media #{$mediaSmall} {
    width: $mainLogoWidth/1.3;
  }
}

#main-home-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav,
.main-nav {
  display: flex;
}

.side-nav {
  margin-left: auto;

  >ul {
    >li {
      >a {
        padding: 1rem !important;
      }
    }
  }

  a {}

  i {
    font-size: 3rem;
    padding: 0;

    &:before {
      padding-right: 0;
    }
  }
}

.main-nav {
  flex: none;

  i {
    &:before {
      font-size: 2.5rem;
    }
  }
}

.inner-main-nav {
  align-items: center;
  margin: 0 2rem 0;

  //font-size: 1.7rem;
  //font-weight: 400;
  //text-transform: uppercase;
  ul {
    >li {
      display: flex;
      margin: 0 1.5rem;

      >a {
        position: relative;
        display: inline-flex;
        white-space: nowrap;
        padding: 0 1rem;
        align-items: center;
        color: inherit;

        &:hover {
          //color: $themeColor1;
        }

        &.active {
          >span {
            position: relative;
            color: #fff;
          }
        }
      }

      &:hover {
        border-bottom: 2px solid #aaa;
      }

      &.active {
        border-bottom: 2px solid #aaa;
      }
    }
  }

  .toggle-mobile-nav {
    display: none;
  }
}

.side-nav,
.main-nav {
  display: flex;
}

.desktop-nav {
  display: flex;
  font-size: 1.8rem;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 2rem;

  >li {
    border-bottom: 1px solid #aaa;

    >a {
      display: block;
      margin: 1.5rem 2rem;
      width: 100%;
    }
  }
}

// Responsive NAV


@media #{$mediaMediumUp} {
  #toggleMainNav {
    display: none;
  }
}


@media #{$mediaMedium} {
  #main-home-link {
    //padding-right: 1rem;
  }

  #main-logo {
    height: 70px;
  }

  .inner-main-nav {
    justify-content: flex-end;

    i {
      font-size: 2rem;

      &:before {
        font-size: inherit;
      }
    }

    ul>li>a {
      padding: 0 1rem;
    }

    .desktop-nav {
      display: none !important;
    }

    .toggle-mobile-nav {
      display: flex;
      font-size: 4rem;
    }
  }

  .main-nav-list {
    font-size: 1.2rem;

    i {
      display: none;
    }
  }

  .main-header {
    display: block !important;
    height: auto !important;
  }
}

@media #{$mediaSmall} {

  #main-logo {
    height: 65px;
  }

  #main-home-link {

    //padding-right: 1rem;
    +h1 {
      display: none;
    }
  }

  #toggleMainNav {
    display: block;
  }

  .inner-main-nav {
    justify-content: flex-end;
    width: auto !important;
    max-width: none !important;
    flex: 1 !important;
    padding-left: .8rem !important;

    .desktop-nav {
      display: none;
    }

    .toggle-mobile-nav {
      display: flex;
      font-size: 4rem;
    }
  }

  .main-header {
    display: block !important;
    height: auto !important;
  }

  //.nav-item-cart {
  //  position: absolute;
  //}
  .main-nav {
    //display: block;
    margin: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    //background: #fff;
    left: 0;
    display: none;
  }

  ul.main-nav-list {
    >li>a {
      background: #fff;
      width: 100%;
      border-top: $primaryBorder;
      display: flex;
      padding: 0.8rem 2.4rem;
    }
  }

  .side-nav {
    li>a {
      padding: 1.1rem 0.6rem !important;
    }

    i {
      font-size: 2.2rem;
    }
  }

  .side-nav-badge {
    font-size: 1rem;
  }

  .side-nav-cart-summary {
    display: none;
  }

  .wrapper-main-nav.nav-open {
    background: #fff;
    position: relative;

    .main-nav {
      z-index: -1;
      display: block;
      box-shadow: 0 0 33px rgba(0, 0, 0, 0.21);
    }
  }

}

// Responsive NAV-|

.back-link-bar {
  $s: 24px;
  //padding: 17px $s/2;
  padding: 18px 12px 9px 12px;
  @extend %clearfix;

  a {
    position: relative;
    background: $themeColor1;
    color: #fff;
    padding: 0 1rem;
    line-height: 24px;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 500;
    border: 1px solid #ffffff;
  }

  a:after,
  a:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  a:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: $themeColor1;
    border-width: 12px;
    margin-top: -12px;
  }

  a:before {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 14px;
    margin-top: -14px;
  }
}



body {
  width: 100%;
  min-width: 100%;
  background: $bodyBg;
}

#main-logo {

  max-height: 7.1rem;
  padding: 1rem 1rem 1rem 1.5rem;
  display: block;
}

.main-header {

  padding: 0;
  background: #232425;
  color: #fff;

  h1 {
    a {
      font-family: $primaryFont;
      color: inherit;
    }
  }

  .search-dropdown {
    color: $textColor;
    text-transform: none;

    @media #{$mediaLarge} {
      //width: 70vw !important;
    }
  }

  @media #{$mediaSmall} {
    //padding: 0 1.3rem;
  }
}



.main-content {
  //display: table-row;
  //overflow: hidden;
  width: 100%;
  overflow: hidden;
  padding: $mainContentPadding;
  //background: $bgColor1;
  //&:after {
  //  content: '';
  //  height: 4rem;
  //  display: block;
  //}
}

a {
  color: $linkColor;
}



// Footer -------/

$footerTextColor: #6b6d6f;

.main-footer {
  width: 100%;
  color: $footerTextColor;
  background: #fff;

  a,
  p {
    color: inherit;
  }

  a:hover {
    color: #fff;
  }

  h2,
  h3 {
    color: inherit;
    font-weight: 600;
    font-family: $primaryFont;
    font-size: 2rem;
    margin-bottom: .4em;
  }
}

.footer-logo-wrap {
  img {
    max-height: 105px;
  }
}

.footer-social-links {

  //> div {display: inline-block}
  a {
    font-size: 1.8rem;
    display: inline-flex;
    align-items: center;
    line-height: 1.5;

    &:before {
      font-size: 1.6em;
    }
  }

  .facebook:before {
    @extend %icon;
    @extend .icon-facebook:before;
  }

  .instagram:before {
    @extend %icon;
    @extend .icon-instagram:before;
  }

  .linkedin:before {
    @extend %icon;
    @extend .icon-linkedin:before;
  }

  .youtube:before {
    @extend %icon;
    @extend .icon-youtube:before;
  }

  .twitter:before {
    @extend %icon;
    @extend .icon-twitter:before;
  }
}

.main-footer-top {
  display: none;
  background: $themeColor1;
  //border-bottom: 1px solid rgba(#fff, .35);
  padding-top: 2rem;
  padding-bottom: 2rem;

  h2 {
    margin: 0;
    line-height: 0;
  }
}

.main-footer-mid {
  padding-top: 5rem;
  padding-bottom: 11rem;
  font-size: 1.3rem;
  line-height: 1.9;

  .footer-group {
    //min-width: 27rem;
  }
}

.main-footer-bottom {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  background: #000;
  color: #fff;

  p {
    margin: 0;
  }
}

// Footer -|




// Generics ---------------------//


.locked-view {
  overflow: hidden;
  position: fixed;
  width: 100%;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
  padding-right: 0 !important;
}

.view-overlay {
  background: #000;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10000;
}


// IEEEEEE

.-ms- {
  body {
    display: block;
  }

  .main-header,
  .main-content,
  .main-footer {
    min-width: 100%;
    display: block;
  }

  .main-header {
    height: auto;
  }

}



.hidden {
  display: none !important;
}