//JMODAL /////////////////////

@mixin JModal() {

  .modal-outer {
    position: fixed;
    display: flex;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 3rem 0;
    left: 0;
    top: 0;
    overflow: hidden;
    overflow-y: scroll;
    background: rgba(214, 214, 214, 0.6);
  }

  ////// Extentions todo: move someplace else
  
  .vModal {


    background: rgba(#090217, .8);

    .modal-content {
      background: transparent;
    }

    .modal-close {
      color: #fff;
      width: 1.9em;
      height: 1.9em;
      font-size: 3rem;
      transition: all .2s;
      &:hover {
        color: #222;
      }
    }

    &.leaving {
      .modal-close {
        opacity: 0;
        transform: scale(1.2);
      }
    }
  }
  
  //////////////////////////////////////////

  .modal-inner {
    min-height: 20rem;
    min-width: 20rem;
    max-width: 100%;
    margin: auto;
    position: relative;
    flex: none;
    //background: $bgColor1;
  }
  
  .modal-content {
    overflow: hidden;
    background: #fff;
  }
  
  .modal-close {
    z-index: 999;
    font-size: 2.2rem;
    position: absolute;
    width: 5rem;
    height: 5rem;
    top: 0;
    right: 0;
    color: $themeColor1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    line-height: 0;
    transition: all .2s;
    
    &:before {
      padding: 0 !important;
      font-family: $iconFont;
      @extend .icon-cross:before;
    }
    
    &:hover {
      background: #fff;
      color: $themeColor1;
    }
  }
}

.modal-form-basic {
  
  .modal-inner {
    
    //padding: 4rem 5rem 0;
    font-size: 1.4rem;
    width: calc(100% - 2rem);
    
    @include respondTo($mediaMediumUp) {
      max-width: 90rem;
    }
    
    .form-header {
      font-size: 1.2em;
      margin-bottom: -1.9rem;
    }
  }
}

@include JModal();