// Revealing Gallery

.artwork-images {

  background: #fff;
  margin-bottom: 0;
  opacity: 0;
  transition: opacity .3s;

  &.rg-initialized {
    opacity: 1;
  }

  .rg-item {
    padding: 0 0.7rem;

    >img {
      height: 100%;
    }
  }
}

.gallery-caption {
  text-align: center;
  background-color: #fff;
  font-size: 2.7rem;
  font-family: $secondaryFont;
  pointer-events: none;

  >.description {
    margin: 0 auto;
    max-width: 50%;
    min-height: 1.9rem;
    padding: 0.25rem 1.5rem;
    display: block;
    font-family: $primaryFont;
    font-size: 1.3rem;
    text-shadow: none;
    color: $textColor;

    &:empty {
      background: none;
    }
  }

  @media #{$mediaSmall} {
    >.description {
      max-width: 100%;
    }
  }
}

.gallery-controls-btn {
  position: absolute;
  font-size: 7rem;
  top: calc(50% - 0.72em);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;

  &:before {
    padding: 0;
  }

  &.btn-next {
    right: 2rem;
  }

  &.btn-prev {
    left: 2rem;
  }

  @media #{$mediaSmall} {
    font-size: 3.6rem;

    &.btn-next {
      right: 0;
    }

    &.btn-prev {
      left: 0;
    }
  }
}

.revealing-gallery {
  height: 33vw;
  min-height: 23rem;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media #{$mediaMedium} {
    height: 40rem;
  }

  @media #{$mediaSmall} {
    height: 30rem;
  }

  &.rg-invalid {
    background: #fff;
    display: flex;
    justify-content: center;
  }
}

.rg-wrap {
  @extend %grab;
  overflow: visible;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  white-space: nowrap;
}

.rg-clone-wrap {
  position: absolute;
  top: 0;
  height: 100%;
}

.rg-end-clones {
  right: 100%;
}

.rg-start-clones {
  left: 100%;
}

.rg-item {

  margin: auto;
  display: inline-block;
  flex: none;
  vertical-align: top;
  height: 100%;
  position: relative;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
  //opacity: .7;
  //transition: opacity .3s;

  &.rg-item-clone {
    &.active {
      //transition: none;
    }
  }

  &.active {

    //opacity: 1;
    //transform: scale(1);
    //border-left: 1px solid #fff;
    //border-right: 1px solid #fff;
    //opacity: 1;
    &:hover {
      //opacity: 1;
    }
  }

  &:hover {
    //opacity: 1;
  }

  &:hover {
    //transition-delay: 0s;
  }
}

// Promoted Items --------------------------------------//


.promoted-items-wrap {

  background-position: 50% 50%;
  background-size: cover;
  margin: 3rem 0 2.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0%;
    left: -10%;
    width: 120%;
    transform: translateY(23%);
    z-index: -1;
    padding-bottom: 25vw;
    background-image: radial-gradient(82% 47%, rgba(0, 0, 0, 0.19), transparent);
  }

  +.cat-nav-outer {
    background: none;
    margin-bottom: 0;

    .cat-nav-container {
      padding-bottom: 0;
    }

    ;
  }

  @media #{$mediaSmall} {
    margin: 2.3rem 0 0.8rem;
  }
}

.promoted-items {

  opacity: 0;
  transition: opacity .3s;

  &.rg-initialized {
    opacity: 1;
  }

  $itemsHeight: calc(24rem + 20vw);
  $itemsWidth: calc(20rem + 32vw);

  position: relative;
  //height: $itemsHeight;
  height: auto;


  &:before {
    content: '';
    width: 100%;
    height: 30%;
    left: 0;
    top: 50%;
    position: absolute;
  }

  .rg-wrap {
    position: relative;
    display: flex;
  }

  .rg-clone-wrap {
    opacity: 1;
    display: flex;
  }

  .rg-item {
    display: inline-flex;
    max-width: 65rem;
    width: $itemsWidth;
    padding: 0 .4rem;
    transform: none;
    top: 0;
    margin: 0;
    height: auto;
  }

  .item {
    display: flex;
    flex-direction: column;
    position: relative;
    background-size: cover;

    .-ms- & {
      display: block;
    }
  }

  .item-bg {
    display: block;
    flex: none;
    width: 100%;
    min-width: 100%;
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
  }

  .item-bg-inner {
    width: 100%;
    min-width: 100%;
    padding-bottom: 75%;
  }

  .item-label {
    width: 100%;
    min-width: 100%;
    flex: 0 1 auto;
    background: #fff;
    text-align: center;
    padding: 2rem;
  }

  .item-content {
    width: 100%;
    height: 100%;
    position: relative;
  }

  header {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 0 2rem;
  }

  .item-subheader {

    margin-top: 0;
    background: #fff;
    color: black;
    display: inline-block;
    padding: 1.6rem 2.6rem;
  }

  .item-artist {
    margin-bottom: .5rem;
    margin-top: 6rem;

    a:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: none;
    margin-bottom: 1em;
    font-size: 3.5rem;
    line-height: 1.2;
  }

  @media #{$mediaSmall} {
    .item {}

    .rg-item {
      width: calc(10rem + 66vw);
      padding: 0rem 0.2rem;
    }
  }


}


// Promoted Items -|