

tr.ajax-order {
  cursor: pointer;
  &:hover {
    background:#f4f4f4 !important;
  }
}

//form validation styles

.form-control {
  position: relative;
  overflow: hidden;

  label {
    font-size: .8em;
    font-weight: 600;
  }

  &:after {
    position: absolute;
    top: 1rem;
    right: 1rem;
    content: '';
    font-family: icomoon;
    display: block;
    transform: scale(0);
    transition: transform .3s;
  }

  #{$textInputSelectors},
  select  {
    height: 5.5rem;
    transition: outline-color .3s;
    outline: none;
    &:focus {
      outline-width: 1px;
    }
    @media #{$mediaSmall} {
      height: 4.9rem;
    }
  }

  &.validation-error {
    #{$textInputSelectors},
    select {
      border-color: $warningColor;
      &:focus {
        border-color: #ebebeb;
      }
    }
  }

  &.validation-success {
    &:after {
      transform: scale(1);
      color: $successColor;

      //top: auto;
      //right: 0.5em;
      //font-size: 1.6em;

      top: -.2em;
      right: 0.2em;
      font-size: 1.4em;

      bottom: 0.3em;

      @extend .icon-check:before;
    }
    #{$textInputSelectors},
    select {
      outline-color: $successColor;
    }
  }

  &.inline-label {

    #{$textInputSelectors},
    select {
      transition: padding .2s;
    }

    &.has-focus,
    &.has-val {
      #{$textInputSelectors},
      select {
        padding-top: 2em;
        padding-left: 1.4rem;
        padding-bottom: .4em;
      }
    }

  }

  &.inline-label > label,
  .validation-message {
    font-family: arial, sans-serif;
    //transition: transform .18s, opacity .18s;
    transition: opacity .18s;
    opacity: 0;
    transform: translateY(-100%);
    position: absolute;
    color: $warningColor;
    text-align: left;
    max-width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    z-index: 1;
    padding: 0 0.6em 0 0;
    top: .4rem;
    right: 0;
  }

  &.has-focus .validation-message {
    color: $textColor;
  }

  &.inline-label.has-val > label,
  &.validation-error .validation-message {
    transform: translateY(0);
    opacity: 1;
  }

  &.inline-label > label {
    transform: translateY(0);
    left: 1.3rem;
    //font-weight: normal;
    //text-transform: uppercase;
    //color: $textColorLighter;
    color: #a7a7a7;
  }

}


input[type="checkbox"],
input[type="radio"] {
  + label:before {
    width: 16px !important;
    height: 16px !important;
  }
}


//// TYPO ///////////////////////////////////////////////


.h-center {
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  font-family: $secondaryFont;
}

.h-lines-center {

  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  font-family: $secondaryFont;
  @include textCenterLines(1rem) {
    background: #848484;
    height: 1px;
  }
}

.text-expressive-01 {

  padding-top: 1.2em;
  padding-bottom: 0;
  font-family: $secondaryFont;
  font-style: italic;
  font-size: 2.6rem;
  font-weight: 300;

  p {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
  }
}

// TYPO |

input:focus {
  box-shadow: inset 1px 2px 13px -5px rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.05) !important;
}

header {
  .quick-search {
    font-weight: 600;
    font-size: 2rem;
    background: #fff;
    color: #fff;

    @media #{$mediaSmall} {
      font-size: 1.6rem;
    }

    .form-control {
      input {
        color: inherit;
        height: 7.5rem;
        background: #4e4e4e;
        border-color: transparent;
      }
    }

    .btn {
      height: 100%;
      @extend .hvr-icon-fade-and-up;
      font-size: 2.3rem;
      position: relative;
      //background: transparent;
      width: 3em;
      margin-left: -3em;
      border: none;
      background: #232425;
      border-radius: 0 !important;
    }
  }
}

.header-vendor-title {
  padding-left: .6em;
  line-height: 1.3;
  font-size: 1.5rem;
}


#theme {

  $roundedFilterRadius: .4rem;

  body:not(.cp) {
    &.main-footer-bottom a { color: #fff !important; }
    &.rounded {

      input {
        border-radius: 10rem;
      }

      .promoted-items {
        .item {
          border-radius: 1rem;
        }
      }

      .quick-search {
        border-radius: 10rem;
        overflow: hidden;
      }

      .filter-inner {

        .dropdown-toggle {
          border-radius: $roundedFilterRadius;
        }
        .active {
          .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .product-item {
        border-radius: .6rem;
      }

      #toTop {
        border-radius: .9rem;
      }
    }

    .quick-search {
      //box-shadow: 0 0 0 1px rgba(0,0,0,0.04);
      border: none;
    }


    // VENDOR ///////////////////////////////////////////////////////////////

    $linkColor: #0098d1;

    $headerBg: #00447d;
    $headerClr: #fff;

    $headerInputBg: #fff;
    $headerInputColor: #111;

    $footerBg: #00447d;
    $footerLinkClr: #fff;
    $footerClr: #fff;

    $btnBg: #0098d1;
    $btnHoverBg: #0061b3;
    $btnClr: #fff;

    $markerClr: #00447d;

    $checkedClr: #000;


    :root {
      --thm-linkColor: $linkColor;

      --thm-headerBg: $headerBg;
      --thm-headerClr: $headerClr;

      --thm-headerInputBg: $headerInputBg;
      --thm-headerInputColor: $headerInputColor;

      --thm-footerBg: $footerBg;
      --thm-footerLinkClr: $footerLinkClr;
      --thm-footerClr: $footerClr;

      --thm-btnBg: $btnBg;
      --thm-btnHoverBg: $btnHoverBg;
      --thm-btnClr: $btnClr;

      --thm-markerClr: $markerClr;

      --thm-checkedClr: $checkedClr;
    }

    ///////////////////////////////////////////////////////////////////////

    *:checked {
      + label {
        &:before {
          box-shadow: inset 0 0 0 0.3em #fff;
        }
      }
    }


    h1, h2, h3, h4, .main-header, .product-item, .cat-nav-list, .dropdown-toggle, .crumb {
      a {
        color: inherit;
        &:hover {
          color: inherit;
        }
      }
    }

    .summary-item, .summary-group-name {
      color: inherit;
      &:hover {
        border-color: transparent;
      }
    }

  }

}



























