
.speak {

  z-index: 99999 !important;

  .message {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.14);
    background: #222;
    color: #f5f5f5;
    padding: 1.1rem 1.3rem;
    overflow: hidden;
    margin-bottom: .4rem;
    font-size: 1.6rem;
    width: 36rem;

    h1, h2 {
      font-size: inherit;
      font-weight: 600;
      margin-bottom: 0.2em;
    }
    p {
      font-size: inherit;
    }
    .icon-check {
      color: $successColor;
    }
    .close-message {
      float: right;
    }

    &.error {
      background: $warningColor;
      color: #fff;
      a {
        color: #fff;
      }
    }
    &.big {
      font-size: 2rem;
    }

  }
  .message-inner {
    > *:last-child {
      margin-bottom: 0;
    }
  }
  .message-container {
    z-index: 999999;
  }
  .upper-right {
    margin-left: auto;
    position: fixed;
    right: 2rem;
    top: 2rem;
    z-index: 999;
  }
  .upper-mid {
    position: fixed;
    text-align: center;
    top: 2rem;
    left: 50%;
    margin-left: -15rem;
    margin-right: auto;
  }

}
