
//
// - Generic layout classes --------------------------------/

.relative {position: relative !important;}
.show-block { display: block !important;}
.show-grid { display: flex !important;}
.hide { display: none !important; }
.text-center {text-align: center;}
.text-right {text-align: right;}
.float-left { float: left; }
.float-right { float: right; }
.nowrap { white-space: nowrap; }

%clearfix {
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    clear: both;
  }
}
.clearfix {
  @extend %clearfix;
}

//
// - Define grid for all ------------------------------------/

@mixin gd() {
  display: flex;
  flex-wrap: wrap;
}
%gd, .gd, .grid {
  @include gd;
}

.grid-row { flex-direction: row; }
.grid-reverse { flex-direction: row-reverse; }
.grid-column { flex-direction: column; }
.grid-column-reverse { flex-direction: column-reverse; }
.grid-nowrap { flex-flow: nowrap;  }
.grid-center { align-items: center; }
.grid-justify-center { justify-content: center; }
.grid-justify-end { justify-content: flex-end; }
.grid-justify-between { justify-content: space-between ;}
.cl, .cell { flex: 1;  }
.collapsefix { flex-basis: auto;  }
.flex-cells > .cell {
  display: flex;
  width: 100%;
}
.cl-content { width: 100%; }

@for $i from 1 through $gridColsTotal {

  $size: 100 / 12 * $i *1%;

  .grid-by#{$i} {
    > .cell {
      flex: 0 0 $size;
      max-width: $size;
    }
  }
  .cell-by#{$i} {
    width: $size !important;
    max-width: $size !important;
    flex: none !important;
  }
}
.cell-auto {
  flex: auto !important;
  max-width: 100% !important;
}
.cell-collapse {
  flex-grow: 0 !important;
  overflow: visible !important;
}
.noflex {
  flex: none !important;
}
.cl-neg-order {
  order: -1;
}
.cl-fill {
  flex: 1 !important;
  max-width: none !important;
}

//
// - Define gutters ----------------------------------------/

@each $size in $guttersSizes {
  $value: map-get($guttersMap, $size);
  .grid-gutters-#{$size} {
    margin-left: -$value;
    margin-top: -$value;
    > .cell {
      padding-left: $value;
      padding-top: $value;
    }
  }
  .grid-gutters-x-#{$size} {
    margin-left: -$value;
    > .cell {
      padding-left: $value;
    }
  }
  .grid-gutters-y-#{$size} {
    margin-top: -$value;
    > .cell {
      padding-top: $value;
    }
  }
}

//
// - Define grid for breakpoints -------------------------------/


@mixin gdBy( $n:$gridColsTotal, $selector: cl, $cols: $gridColsTotal ) {
  $size: 100/$cols*$n * 1%;
  > .#{$selector} {
    flex: 0 0 $size;
    max-width: $size;
  }
}

@mixin clBy($n:$gridColsTotal, $cols: $gridColsTotal) {
  $size: 100/$cols*$n * 1%;
  width: $size !important;
  max-width: $size !important;
  flex: none !important;
}

// loop throung breakpoints
@each $breakpoint in map_keys($gridBreakpoints) {

  // define grid for breakpoint

  $value: map_get($gridBreakpoints, $breakpoint);

  @media #{$value} {

    .#{$breakpoint}-relative {position: relative !important;}
    .#{$breakpoint}-show-block { display: block !important;}
    .#{$breakpoint}-show-grid { display: flex !important;}
    .#{$breakpoint}-hide { display: none !important; }
    .#{$breakpoint}-text-center {text-align: center;}
    .#{$breakpoint}-text-right {text-align: right;}

    .#{$breakpoint}-gd {
      display: flex;
      flex-wrap: wrap;
    }
    .#{$breakpoint}-grid-row { flex-direction: row; }
    .#{$breakpoint}-grid-reverse { flex-direction: row-reverse; }
    .#{$breakpoint}-grid-column { flex-direction: column; }
    .#{$breakpoint}-grid-column-reverse { flex-direction: column-reverse; }
    .#{$breakpoint}-block { display: block; }
    .#{$breakpoint}-no-cell { flex: initial; }
    .#{$breakpoint}-grid-center { align-items: center; }
    .#{$breakpoint}-cell-content { width: 100%; }
    .#{$breakpoint}-cell-neg-order { order: -1; }

    @for $i from 1 through $gridColsTotal {
      $size: 100/12*$i * 1%;
      .#{$breakpoint}-grid-by#{$i} {
        > .cell {
          flex: 0 0 $size;
          max-width: $size;
        }
      }
      .#{$breakpoint}-cell-by#{$i} {
        width: $size !important;
        max-width: $size !important;
        flex: none !important;
      }
    }

  }
}

@mixin gtrs($s, $selector: 'cl') {
  margin-left: -$s;
  margin-top: -$s;
  > .#{$selector} {
    padding-left: $s;
    padding-top: $s;
  }
}

//@mixin crossHairGrid($s, $selector: 'cl') {
//  overflow: hidden;
//  position: relative;
//  bottom: -$s;
//  right: -$s;
//
//}

@mixin gtrsX($s, $selector: 'cl') {
  margin-left: -$s;
  > .#{$selector} {
    padding-left: $s;
  }
}

@mixin gtrsY($s, $selector: 'cl') {
  margin-top: -$s;
  > .#{$selector} {
    padding-top: $s;
  }


}

//
// - Define wrappers ---------------------------------------/

.page-constrain {
  @media (min-width: 1684px) { padding: 0 calc(50% - 780px); }
  @media (max-width: 1683px) and (min-width: 1400px) { padding: 0 4.5%; }
  @media (max-width: 1399px) and (min-width: 961px) { padding: 0 4.5%; }
  @media (max-width: 960px) and (min-width: 721px) { padding: 0 4%; }
  @media (max-width: 720px) { padding: 0 1rem; }
}
.page-constrain-more {
  @media (min-width: 1684px) { padding: 0 calc(50% - 650px); }
  @media (max-width: 1683px) and (min-width: 1400px) { padding: 0 10%; }
  @media (max-width: 1399px) and (min-width: 961px) { padding: 0 7%; }
  @media (max-width: 960px) and (min-width: 721px) { padding: 0 6%; }
  @media (max-width: 720px) { padding: 0 1rem; }
}

@media #{$mediaMedium} {
  .medium-no-constrain {
    padding: 0;
  }
}

@media #{$mediaSmall} {
  .small-no-constrain {
    padding: 0;
  }
}

@mixin respondTo($m) {
  @if($m) {
    @media #{$m} {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin paddingY($v) {
  padding-top: $v;
  padding-bottom: $v;
}



@mixin paddingX($v) {
  padding-left: $v;
  padding-right: $v;
}

html {
  @include respondTo($mediaSmall) {
    font-size: 58%;
  }
}
