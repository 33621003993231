

// General ------------------------//

.small-print {
  font-size: $smallPrintSize;
  color: $smallPrintColor;
  @media #{$mediaSmall} {
    font-size: $smallPrintMobileSize;
  }
}

// Heading ------------------------//

$headerBorderHeight: 4px;
$headerBorderColor: $themeColor1;

%h1-article {
  font-size: 3rem;
  position: relative;
  text-align: center;
  line-height: 1em;
  padding-bottom: .5em;
  padding-top: .5em;
  margin-bottom: 1.2em;
  text-transform: none;
  //font-weight: 400;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 68px);
    height: 0;
    width: 136px;
    border-bottom: 1px dashed $themeColor1;
  }
  &+ .h1-article-sub-text {
    margin-top: -3rem;
    margin-bottom: 3rem;
  }
}
.h1-article {
  @extend %h1-article;
}

%h2-bg-stripe {
  font-size: 1.3rem;
  margin-bottom: 0;
  padding: 0.2em 0.7em;
  color: #ffffff;
  background: $bgDark2;
  font-weight: 600;
  text-transform: uppercase;
}

h1 {
  //margin: 0 0 .6em;
  //line-height: 1;
}
h2 {
  //font-size: 2.1rem;
  margin-bottom: .5em;
  @media #{$mediaMediumBelow} {
    //font-size: 2.3rem;
  }
}
h3 {
  //font-weight: 700;
  //font-size: inherit;
}

h1, h2, h3, h4  {
  //font-family: $secondaryFont;
  //color: #000;
}


// Icons -------------------------//

//@import "icons/style.css";
@font-face {
  font-family: icomoon;
  src: url('icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal; }

%icon {
  font-family: icomoon;
  font-style: normal !important;
  font-weight: normal !important;
  //vertical-align: middle;
  padding-right: .3em;
}
[class*="icon-"] {
  vertical-align: middle;
  &:before {
    @extend %icon;
  }
}

// Icon selectors

.icon-external-link:before {
  content: "\f08e";
}
.icon-cultural-heritage:before {
  content: "\e940";
}
.icon-building:before {
  content: "\e904";
}
.icon-chair:before {
  content: "\e905";
}
.icon-eye:before {
  content: "\e906";
}
.icon-nature:before {
  content: "\e907";
}
.icon-picasso2:before {
  content: "\e908";
}
.icon-sculpture:before {
  content: "\e909";
}
.icon-add-to-list:before {
  content: "\e936";
}
.icon-remove-user:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e90b";
}
.icon-chevron-left:before {
  content: "\e90c";
}
.icon-chevron-right:before {
  content: "\e90d";
}
.icon-chevron-small-down:before {
  content: "\e942";
}
.icon-chevron-small-left:before {
  content: "\e943";
}
.icon-chevron-small-right:before {
  content: "\e944";
}
.icon-chevron-small-up:before {
  content: "\e945";
}
.icon-chevron-thin-down:before {
  content: "\e900";
}
.icon-chevron-thin-left:before {
  content: "\e901";
}
.icon-chevron-thin-right:before {
  content: "\e902";
}
.icon-chevron-thin-up:before {
  content: "\e903";
}
.icon-chevron-up:before {
  content: "\e90e";
}
.icon-chevron-with-circle-down:before {
  content: "\e946";
}
.icon-chevron-with-circle-left:before {
  content: "\e947";
}
.icon-chevron-with-circle-right:before {
  content: "\e948";
}
.icon-chevron-with-circle-up:before {
  content: "\e949";
}
.icon-dots-three-horizontal:before {
  content: "\e94a";
}
.icon-dots-three-vertical:before {
  content: "\e94b";
}
.icon-flow-tree:before {
  content: "\e937";
}
.icon-resize-100:before {
  content: "\e90f";
}
.icon-resize-full-screen:before {
  content: "\e911";
}
.icon-trash:before {
  content: "\e912";
}
.icon-triangle-down:before {
  content: "\e94c";
}
.icon-triangle-left:before {
  content: "\e94d";
}
.icon-triangle-right:before {
  content: "\e94e";
}
.icon-triangle-up:before {
  content: "\e913";
}
.icon-add-user:before {
  content: "\e914";
}
.icon-area-graph:before {
  content: "\e915";
}
.icon-book:before {
  content: "\e932";
}
.icon-chat:before {
  content: "\e94f";
}
.icon-check:before {
  content: "\e916";
}
.icon-cog:before {
  content: "\e917";
}
.icon-colours:before {
  content: "\e938";
}
.icon-cross:before {
  content: "\e918";
}
.icon-cycle:before {
  content: "\e919";
}
.icon-database:before {
  content: "\e933";
}
.icon-dial-pad:before {
  content: "\e91a";
}
.icon-edit:before {
  content: "\e91b";
}
.icon-email:before {
  content: "\e91c";
}
.icon-emoji-happy:before {
  content: "\e939";
}
.icon-emoji-neutral:before {
  content: "\e93a";
}
.icon-emoji-sad:before {
  content: "\e93b";
}
.icon-heart-outlined:before {
  content: "\e91d";
}
.icon-heart:before {
  content: "\e91e";
}
.icon-home:before {
  content: "\e91f";
}
.icon-hour-glass:before {
  content: "\e920";
}
.icon-images:before {
  content: "\e921";
}
.icon-info:before {
  content: "\e93c";
}
.icon-link:before {
  content: "\e93d";
}
.icon-list:before {
  content: "\e950";
}
.icon-location-pin:before {
  content: "\e922";
}
.icon-location:before {
  content: "\e93e";
}
.icon-log-out:before {
  content: "\e923";
}
.icon-login:before {
  content: "\e924";
}
.icon-magnifying-glass:before {
  content: "\e951";
}
.icon-search:before {
  content: "\e951";
}
.icon-mail:before {
  content: "\e925";
}
.icon-minus:before {
  content: "\e926";
}
.icon-new-message:before {
  content: "\e927";
}
.icon-news:before {
  content: "\e93f";
}
.icon-palette:before {
  content: "\e941";
}
.icon-plus:before {
  content: "\e928";
}
.icon-quote:before {
  content: "\e929";
}
.icon-swap:before {
  content: "\e92a";
}
.icon-tag:before {
  content: "\e92b";
}
.icon-tools:before {
  content: "\e92c";
}
.icon-user:before {
  content: "\e952";
}
.icon-users:before {
  content: "\e953";
}
.icon-facebook:before {
  content: "\e92d";
}
.icon-instagram:before {
  content: "\e934";
}
.icon-linkedin:before {
  content: "\e92e";
}
.icon-pinterest:before {
  content: "\e935";
}
.icon-twitter:before {
  content: "\e92f";
}
.icon-vimeo:before {
  content: "\e930";
}
.icon-youtube:before {
  content: "\e931";
}
.icon-menu:before {
  content: "\e910";
}


// extend icon classes


.icon-close {
  &:before {
    @extend .icon-cross:before;
  }
}

.icon-add_box {
  &:before {
    @extend .icon-plus:before;
  }
}

.icon-more_vert {
  &:before {
    @extend .icon-dots-three-vertical:before;
  }
}

.icon-marker {
  &:before {
    @extend .icon-location-pin:before;
  }
}

.icon-zoom {
  &:before {
    @extend .icon-magnifying-glass:before;
  }
}

.icon-account:before {
  @extend .icon-tools:before;
}

.icon-trash-o:before {
  @extend .icon-trash:before;
}




