

.product-item {
  @include item;
}
.item-image-wrap {
  @include itemImageWrap;
}


.item-header {
  @include itemHeader;
}

.item-timer {
  @include itemTimer;
}
.item-footer {
  @include itemFooter;
}
.item-content {
  @include itemContent;
}


.product-list-horizontal {
  .product-item {

  }
  @include itemHorizontal;
}
.product-list-vertical {
  .product-item {

  }
  .item-header,
  .item-footer {
    width: 100%;
  }
  @include itemVertical;
}
