
@function whenLight ($clr, $light:null, $dark:null) {
    @if(lightness($clr) < 50) {
        @return $light;
    } @else {
        @return $dark;
    }
}

$SM-triggerBg: #111 !default;
$SM-triggerClr: whenLight($SM-triggerBg, #fff, #111) !default;
$SM-headerBg: #111 !default;
$SM-headerClr: whenLight($SM-headerBg, #fff, #111) !default;

.SimpleMessage {

    svg {
        display: block;
        color: inherit;
    }
    button {
        color: inherit;
    }
    .ico {
        fill: currentColor;
    }

    &-triggerWrap,
    &-messageContainer {
        position: fixed;
        right: 1rem;
        bottom: 1rem;
        z-index: 1000;
    }

    &-messageContainer {
        width: 30rem;
        max-height: calc(100% - 2rem);
        display: flex;
        flex-direction: column;
        background: #fff;
        box-shadow: 0px -8px 15px -2px rgba(78, 78, 78, 0.48);
    }

    &-head {
        background: $SM-headerBg;
        color: $SM-headerClr;
        display: flex;
        flex: none;

        &-title {

        }

        .ico {
            width: 3rem;
            height: 3rem;
        }
    }

    &-body {
        flex: 1;
        overflow: auto;
    }
    &-foot {
        display: flex;
        border-top: 1px solid #ccc;

        &-email {
            flex: 1;
        }
        &-send {

        }
        .ico {
            width: 4rem;
            height: 4rem;
        }
    }

    &-closeBtn{
        background: transparent;
        display: block;
        margin-left: auto;
        padding: .6rem;
    }

    &-message {
        margin: 1rem;
        font-size: 1.2rem;
        padding: 1rem 1.4rem;
        width: 80%;
        background: #f2f3f2;

        &_system {
            border-radius: 0 2.4rem 2.4rem 2.4rem;
        }
        &_user{
            margin-left: auto;
            border-radius: 2.4rem 0 2.4rem 2.4rem;
            text-align: right;
        }
        &_error {
            color: #c71515;
        }
    }

    &-messageInput {
        margin: 1rem;
        font-size: 1.2rem;
        padding: 1rem 1.4rem;
        border: 1px solid #ccc;
        border-radius: 1.8rem;
    }
    &-emailInput {
        height: 100%;
        width: 100%;
        padding: 0 1em;
    }

    &-sendBtn {
        background: transparent;
        height: 100%;
        padding: 0 1em;
    }

    &-triggerWrap {
        background: $SM-triggerBg;
        color: $SM-triggerClr;
        border-radius: 50%;
        width: 6rem;
        height: 6rem;
    }
    &-trigger {
        background: transparent;
        width: 100%;
        height: 100%;

        .ico {
            width: 3.5rem;
            height: 3.5rem;
            margin: auto;
        }
    }
}
