
.videoPlayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.videoPlayBtn-circle {
  width: 10rem;
  height: 10rem;
  background: #fff;
  border-radius: 50%;
  position: relative;
  border: 1px solid #000;
  transition: all .2s;
  
  .videoPlayBtn:hover & {
    background: #000;
    border-color: #fff;
  }
}

.videoPlayBtn-arrow {
  border: 16px solid;
  border-color: transparent;
  border-left-color: #000;
  border-right-width: 0;
  border-left-width: 22px;
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-38%, -50%);
  transition: all .2s;
  
  .videoPlayBtn:hover & {
    border-left-color: #fff;
  }
}

.videoPlayBtn-caption {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: .3rem .3rem;
  background: rgba(#000, .3);
  transform: translateX(-50%);
  margin-top: 1rem;
  text-align: center;
  color: #fff;
}

@include respondTo($mediaSmall) {

}