
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.hide-overflow {
  max-width: 100%;
  overflow: hidden;
}

i.nopad,
.icon-nopad {
  &:before {
    padding-right: 0;
  }
}

.locked-view {
  overflow: hidden;
  position: fixed;
  width: 100%;
  -webkit-backface-visibility:hidden;
  box-sizing: border-box;
}
