

// reset form elements
#{$textInputSelectors},
select,
input,
button,
fieldset,
textarea {
  //@include appearance(none);
  //border: none;
  //margin: 0;
  //padding: 0;
}
select::-ms-expand {
  display: none;
}
form {
  border: none;
  margin: 0;
  padding: 0;
}

#{$textInputSelectors},
select,
textarea {

  &:focus {

  }

}

label {
  @include user-select(none);
}

textarea {
  width: 100%;
  max-width: 100%;
  font: inherit;
}


// - Buttons  -------------------------------------------------------/



.fc-wrap-textarea {
  min-width: 100%;
  textarea {
    width: 100%;
  }
}



// - Single form -------------------------------------------------------/

.singleForm {
  position: relative;
  display: flex;
  z-index: 1;
}

.singleForm-textInput {
  border: none;
  height: unset;
  flex: 1;
  position: relative;
  z-index: 1;
  background: transparent;
}

.singleForm-outerStyle {
  @include absoluteFiller;
  z-index: 0;
  pointer-events: none;
}






.ui-btn {
  $self: &;
  display: inline-flex;
  align-items: center;
  padding: 1.6rem 2rem !important;
  border-radius: 0.5rem;
  background: #eee;
  transition: background-color .25s, box-shadow .25s;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;

  &-primary {
    @extend .ui-btn;
    background-color: var(--cp-darkColor);
    color: #fff;
  }
}



/*
<div class="form-group">
        <label>Normal text:</label>
        <div class="input-group">
            <input type="text" name="foobar">
        </div>
    </div>
*/
/// FORM GROUPS 
.form-group {
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  margin-bottom: .3rem;
  background-color: pink;
  

  &-inline {
    @extend .form-group;
    flex-direction: row;
    align-items: center;

    > label, .input-label {
      width: 10em;
      margin-right: .3rem;
    }
  }

  > label, .input-label {
    .hint {
      color: #999;
      font-weight: 400;
      padding-left: .3rem;
    }
  }
  .form-input {
    flex: 1;

    &-fill {
      @extend .form-input;
      input, textarea, select {
        width: 100%;
        font-size: 1em;
        padding: .5rem;
      }
    }
  }

}